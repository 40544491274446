import { BlockContainer } from '@components/Blocks';
import { CardBase } from '@components/Card/useCard';
import CardGeneric from '@components/CardGeneric';
import CardGenericWide from '@components/CardGeneric/CardGeneric.Wide';
import { createBlock } from '@lib/features/blocks';

export type SingleCardBlockProps = {
  variant?: 'default' | 'feature';
};

const SingleCardBlock = createBlock<'blocks_singleCardExternal_BlockType', SingleCardBlockProps>(
  ({ variant = 'default', /* websiteUrl, */ linkCta, heading, content, flipAlignment, image }) => {
    const itemProps: CardBase = {
      title: heading,
      description: content,
      image,
      href: linkCta?.href,
      customText: linkCta?.customText,
    };
    const newWindow = linkCta?.target === '_blank';

    switch (variant) {
      case 'feature':
        return (
          <BlockContainer>
            <CardGenericWide newWindow={newWindow} flipAlignment={flipAlignment} item={itemProps} />
          </BlockContainer>
        );
      default:
        return <CardGeneric newWindow={newWindow} fullHeight item={itemProps} />;
    }
  }
);

export default SingleCardBlock;
