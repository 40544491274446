import EntryCardBlock from '@component/EntryCard/EntryCardBlock';
import Grid from '@componentPrimitives/Grid';
import { BlockContainer } from '@components/Blocks';
import SingleCardBlock from '@components/SingleCardBlock';
import { createBlock } from '@lib/features/blocks/blocks';

const DualCards = createBlock<'blocks_dualCards_BlockType'>(({ children }) => {
  return (
    <BlockContainer>
      <Grid rowGutter>
        {children?.map((item, i) => (
          <Grid.Col key={i} md={6}>
            {item.__typename === 'blocks_entryCard_BlockType' ? (
              <EntryCardBlock {...item} />
            ) : (
              <SingleCardBlock {...item} />
            )}
          </Grid.Col>
        ))}
      </Grid>
    </BlockContainer>
  );
});
export default DualCards;
