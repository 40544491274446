import { BlockExtrasQueryVariables, getSdk } from './_generated/blockExtras.generated';
import { makeNonNullableArray, maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import { Entry, parseEntry } from '../entries';
import client from '@lib/fetch/client';
import { EntriesFragment } from '@gql/_generated/entries.generated';
import { withSite } from '@lib/hooks';

gql`
  query blockExtras($latestNews: Boolean = false) {
    ...latestArticlesBlockExtras @include(if: $latestNews)
  }
`;

/**
 * Make any subsequent queries that may be required by 'Blocks' in the current View
 * For example, the 'latest news' block requires article cards
 */
export const getBlockExtras = async (entry: Entry) => {
  const viewBlocks = maybeGet(entry, 'blocks') ?? [];

  const sdk = getSdk(client);

  // check the current view for instances of particular blocks
  const variables: BlockExtrasQueryVariables = {
    latestNews: !!viewBlocks.find((v) => v.__typename === 'blocks_latestArticles_BlockType'),
  };

  const extras = await sdk.blockExtras(withSite(variables));

  // ----------------------------------------------------------------------------------------------
  // --- Latest Article ---
  const maybeLatestArticles = makeNonNullableArray(extras.latestArticles) as EntriesFragment[];
  const latestArticles =
    (maybeLatestArticles.map((v) =>
      parseEntry(v, 'article_default_Entry')
    ) as Entry<'article'>[]) ?? [];

  return { latestArticles };
};

export type BlockExtras = NonNullable<ReturnOrPromiseType<typeof getBlockExtras>>;
