import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { LatestArticlesBlockExtrasFragmentDoc } from '../../../../gql/_generated/blocks.generated';
import { ArticleCardFragmentDoc, EntryBaseFragmentDoc, AuthorFragmentDoc } from '../../../../gql/_generated/entries.generated';
import { ImageFragmentDoc, ImageBaseFragmentDoc, ImageSize_CommonFragmentDoc, ImageSize_OriginalFragmentDoc, ImageSize_BannerCropFragmentDoc, ImageSize_FullFitFragmentDoc, ImageSize_LandscapeLgCropFragmentDoc, ImageSize_LandscapeMdCropFragmentDoc, ImageSize_LandscapeSmCropFragmentDoc, ImageSize_SquareLgCropFragmentDoc, ImageSize_SquareMdCropFragmentDoc, ImageSize_SquareSmCropFragmentDoc, ImageSize_SquareSmFitFragmentDoc } from '../../images/_generated/image.generated';
export type BlockExtrasQueryVariables = Types.Exact<{
  latestNews?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type BlockExtrasQuery = { __typename?: 'Query', latestArticles?: Array<{ __typename: 'article_default_Entry', entrySummary?: string | null, typeHandle: string, searchScore?: number | null, id?: string | null, uri?: string | null, url?: string | null, title?: string | null, sectionHandle: string, postDate?: any | null, entryImage: Array<{ __typename?: 'assetsVolume_Asset', mimeType?: string | null, id?: string | null, alt?: string | null, title?: string | null, url?: string | null, height?: number | null, width?: number | null, original_url?: string | null, original_height?: number | null, original_width?: number | null, bannerCrop_url?: string | null, bannerCrop_width?: number | null, bannerCrop_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeMdCrop_url?: string | null, landscapeMdCrop_width?: number | null, landscapeMdCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareMdCrop_url?: string | null, squareMdCrop_width?: number | null, squareMdCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null } | null>, author?: { __typename: 'User', displayName?: string | null } | null } | null> | null };


export const BlockExtrasDocument = gql`
    query blockExtras($latestNews: Boolean = false) {
  ...latestArticlesBlockExtras @include(if: $latestNews)
}
    ${LatestArticlesBlockExtrasFragmentDoc}
${ArticleCardFragmentDoc}
${EntryBaseFragmentDoc}
${AuthorFragmentDoc}
${ImageFragmentDoc}
${ImageBaseFragmentDoc}
${ImageSize_CommonFragmentDoc}
${ImageSize_OriginalFragmentDoc}
${ImageSize_BannerCropFragmentDoc}
${ImageSize_FullFitFragmentDoc}
${ImageSize_LandscapeLgCropFragmentDoc}
${ImageSize_LandscapeMdCropFragmentDoc}
${ImageSize_LandscapeSmCropFragmentDoc}
${ImageSize_SquareLgCropFragmentDoc}
${ImageSize_SquareMdCropFragmentDoc}
${ImageSize_SquareSmCropFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    blockExtras(variables?: BlockExtrasQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlockExtrasQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BlockExtrasQuery>(BlockExtrasDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'blockExtras', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;